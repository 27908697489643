import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import RGA from "react-ga"
import "../styles/variables.css"

const Wrapper = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1000;
    margin-top: clamp(90px, 5vw, 0px);

    h1 {
        margin: 1.4em 0 0!important;
        font-size: var(--header-1);
        color: var(--yellow);
        font-family: Magnificent, serif;
        text-transform: uppercase;
        font-weight: 400;
        text-shadow: 0px 2px 0px #201A2C;
        text-align: center;
    }

    h3 {
        color: var(--yellow);
        font-family: "The Nightmare", serif;
        font-size: var(--header-2);
        font-weight: 400;
        margin: 0 0 0.6em;
        text-shadow: 0px 2px 0px #201A2C;
    }

    p {
        color: var(--white);
        font-family: "Biko", sans-serif;
        font-weight: 400;
        text-align: center;
        max-width: 80%;

    }
`

const Links = styled.section`
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    width: 80%;
    font-family: "Biko", sans-serif;
    font-weight: 400;
    
    .links {
        cursor: pointer;
        width: 200px;
        height: 50px;
        border: none;
        font: inherit;
        outline: inherit;
        border-radius: 4px;
        color: white;
        background: none;
        border: 1px solid #B13059;
        transition: .3s ease-in-out;
    }

    .links:hover {
      transform: scale(1.1);
    }

    .CTA.links {
        background-color: #B13059;
        color: white;
    }

    @media screen and (max-width: 900px){
        flex-direction: column;

        .links {
            width: 100%;
            margin-bottom: 1em;
        }
    }
`

export default () => {
    return (
        <Wrapper>
            <h1>LIONS WONDERLAND</h1>
            <h3>Into the Lions Den</h3>
            <p> Join passionate communities of like-minded individuals, forge meaningful connections, and grow stronger together along the way.</p>
            <Links>
                <Link 
                    to="/organizations"
                    onClick={() => {
                        RGA.event({
                          category: "see-orgs",
                          action: "links-click",
                        })
                    }}
                    >
                    <button className="CTA links">See All Orgs</button>
                </Link>
                <Link 
                    to="/about-recweek"
                    onClick={() => {
                        RGA.event({
                          category: "about-recweek",
                          action: "links-click",
                        })
                    }}
                    >
                    <button className="links">About RecWeek</button>
                </Link>
                <Link 
                    to="/about-us"
                    onClick={() => {
                        RGA.event({
                          category: "about-us",
                          action: "links-click",
                        })
                    }}
                    >
                    <button className="links">About LIONS</button>
                </Link>
            </Links>
        </Wrapper>
    )
}