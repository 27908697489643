import React from "react"
import styled from "styled-components"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { ReactComponent as Fb } from "../assets/facebook-link.svg"
import { ReactComponent as Twitter } from "../assets/twitter-link.svg"
import { ReactComponent as Instagram } from "../assets/instagram-link.svg"
import "../styles/variables.css"

const FooterWrapper = styled.footer`
  background-color: var(--indigo-300);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 54px 200px 20px;
  // @media screen and (max-width: 1300px) {
  //   padding: 24px 175px;
  // }
  // @media screen and (max-width: 1200px) {
  //   padding: 24px 150px;
  // }
  @media screen and (max-width: 1100px) {
    padding: 24px 0;
  }
  // @media screen and (max-width: 1040px) {
  //   padding: 24px 50px;
  // }
  // @media screen and (max-width: 760px) {
  //   padding: 24px 30px;
  // }
  // @media screen and (max-width: 600px) {
  //   padding: 24px;
  // }
  // @media screen and (max-width: 500px) {
  //   padding: 24px 15px;
  // }

  .footerlink{
    svg {
      transition: .2s ease-in-out;
    }
    svg:hover {
      transform: translateY(-10px);
    }
  }

  hr {
    display: none;
  }

  .menu {
    margin: auto!important;
  }
  .menu a {
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    text-decoration: none;
    padding-top: 10px;
    text-align: center;
  }
  .menu a.join {
    background-color: #fff;
    border: 2px solid #fff;
    border-radius: 4px;
    box-sizing: border-box;
    color: #ff7d21;
    font-size: 14px;
    font-weight: 700;
    padding: 13px 35px 12px 35px;
    text-decoration: none;
    transition-delay: 0s;
    transition-duration: 0.2s;
    transition-property: all;
    transition-timing-function: ease-out;
  }

  > *:not(:last-child),
  div a:not(:last-child) {
    margin-right: 20px;
  }

  > .menu .join {
    margin-left: auto;
  }
  // .social {
  //   margin-left: auto;
  // }
  @media screen and (max-width: 720px) {
    display: grid;
    // grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto;
    // grid-row-gap: 12px;
    grid-template-areas:
      "logo social"
      "border border"
      "menu menu";
    .social {
      grid-area: social;
      margin-left: auto;
    }
    img {
      grid-area: logo;
    }
    hr {
      display: block;
      background: none;
      width: 100%;
      border: 0.5px solid #4f4f4f;
      grid-area: border;
    }
    .menu {
      display: flex;
      justify-content: space-around;
      grid-area: menu;
      margin: 0;
      // a {
      //   margin-right: 0 !important;
      // }
    }
  }
`

const ByLineWrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0;
  padding-bottom: 54px;
  background-color: var(--indigo-300);
  color: white;
  span {
    font-size: 16px;
    letter-spacing: 0.07em;
  }

  img {
    margin-right: 8px;
  }
  .be-proud {
    color: #DFA839;
    position: relative;
    text-decoration: none;
    transition: .5s ease-in-out;

    &::after {
      content: "";
      position: absolute;
      display: block;
      height: 1px;
      width: 100%;
      background-color: #DFA839;
      left: 0;
      right: 0;
      bottom: -6px;
      transition: .5s ease-in-out;
    }

    &::before {
      content: '';
      position: absolute;
      height: calc(100% + 12px);
      width: 0px;
      top: -6px;
      left: -4px;
      transition: .7s ease-in-out;
    }

    &:hover {
      // color: var(--magenta-300);

      &::after {
        opacity: 0;
      }

      &::before {
        width: calc(100% + 8px);
        border: 1px solid #DFA839;
      }
    }
  }

  @media screen and (max-width: 520px) {
    span {
      font-size: 10.25px;
    }
  }
`

function Footer() {
  return (
    <FooterWrapper>
      <div className="social">
        <OutboundLink
          href="https://www.facebook.com/admu.lions"
          target="_blank"
          rel="noopener noreferrer"
          className="footerlink"
        >
          <Fb />
        </OutboundLink>
        <OutboundLink
          href="https://www.twitter.com/admulions"
          target="_blank"
          rel="noopener noreferrer"
          className="footerlink"
        >
          <Twitter />
        </OutboundLink>
        <OutboundLink
          href="https://www.instagram.com/admu.lions"
          target="_blank"
          rel="noopener noreferrer"
          className="footerlink"
        >
          <Instagram />
        </OutboundLink>
      </div>
    </FooterWrapper>
  )
}
function ByLine() {
  return (
    <ByLineWrapper>
      <span>
        Passionately Crafted by{" "}
        <OutboundLink
          className="be-proud"
          href="https://uxsociety.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          User Experience Society
        </OutboundLink>
      </span>
    </ByLineWrapper>
  )
}
const Wrapper = styled.section`
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-top: 0;
  font-family: "Biko", sans-serif;
  @media screen and (max-width: 700) {
    margin: 0 !important;
    margin-top: 0 !important;
  }
`
export default ({ data, margin }) => {
  return (
    <Wrapper margin={margin}>
      {/* <Closing /> */}
      <Footer logo={data.logo} />
      <ByLine />
    </Wrapper>
  )
}
