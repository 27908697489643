import React, {useRef, useEffect} from "react"
import styled from "styled-components"
import aod from "../assets/hero-assets/aceofdiamonds.webp"
import alice from "../assets/hero-assets/Alice.webp"
import bookshelf from "../assets/hero-assets/Bookshelf.webp"
import clock from "../assets/hero-assets/Clock.webp"
import cup from "../assets/hero-assets/Cup.webp"
import pot from "../assets/hero-assets/Pot.webp"
import qoh from "../assets/hero-assets/queenofhearts.webp"
import rabbit from "../assets/hero-assets/Rabbit.webp"
import Links from "../components/Links"
import { gsap } from "gsap"
import "../styles/variables.css"

const HeaderWrapper = styled.header`
  padding-top: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 6em;
  @media screen and (max-width: 1100px) {
    padding: 32px 100px 0;
  }
  @media screen and (max-width: 1040px) {
    padding: 32px 50px 0;
  }

  @media screen and (max-width: 760px) {
    padding: 32px 30px 0;
  }

  @media screen and (max-width: 600px){
    margin-bottom: 0;
  }
`

export const SvgWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  margin: 20px 0 -160px;


  .hero-grid-container {
    width: 80%;
    aspect-ratio: 1.7;
    display: grid;

    grid-template-rows: repeat(8, 1fr);
    grid-template-columns: repeat(19, 1fr);

    .grid-element {
      height: 100%;
      width: 100%;
    }

    #bookshelf-id {
      grid-row: 2 / span 6;
      grid-column: 1 / span 7;
      z-index: 0;
    }

    #clock-id {
      grid-row: 2 / span 6;
      grid-column: 13 / span 8;
      z-index: 0;
    }

    #alice-id {
      grid-row: 2 / span 6;
      grid-column: 7 / span 7;
      z-index: 1;
    }

    #pot-id {
      grid-row: 6 / span 2;
      grid-column: 7 / span 3;
      z-index: 2;
    }

    #cup-id {
      grid-row: 6 / span 1;
      grid-column: 12 / span 2;
      z-index: 2;
    }

    #rabbit-id {
      grid-row: 3 / span 2;
      grid-column: 13 / span 3;
      z-index: 2;
    }

    #aod-id {
      grid-row: 2 / span 1;
      grid-column: 12 / span 1;
      z-index: 2;
      transform: rotate(42deg) scale(.5);
    }

    #qoh-id {
      grid-row: 7 / span 1;
      grid-column: 11 / span 1;
      z-index: 2;
      transform: rotate(-52deg) scale(.4);
    }

    .ios-stretch-fix {
      height: intrinsic;
    }
  }
`

export default function Hero({ header }) {
  let aodPic = useRef(null)
  let alicePic = useRef(null)
  let bookshelfPic = useRef(null)
  let clockPic = useRef(null)
  let cupPic = useRef(null)
  let potPic = useRef(null)
  const fallingRate = 1;
  let qohPic = useRef(null)
  let rabbitPic = useRef(null)
  // let tl = useRef(null)

  useEffect(() => {
    // tl.current = gsap.timeline()
    // .fromTo(
    //   alicePic, 
    //   {"y": -1000, opacity: 0},
    //   {"y": 0, opacity: 100, duration: 1.5}
    // )
    // .fromTo(
    //   bookshelfPic, 
    //   {"y": -1000, opacity: 0},
    //   {"y": 0, opacity: 100, duration: 1.5},
    //   clockPic,
    //   {"y": -1000, opacity: 0},
    //   {"y": 0, opacity: 100, duration: 1.5},
    // )
    gsap.fromTo(
      alicePic, 
      {"y": -1000},
      {"y": 0, duration: 1.5*fallingRate}
    )

    gsap.fromTo(
      bookshelfPic, 
      {"y": -1000},
      {"y": 0, duration: 1.5*fallingRate, delay: .4}
    )

    gsap.fromTo(
      clockPic, 
      {"y": -1000},
      {"y": 0, duration: 1.5*fallingRate, delay: .4}
    )

    gsap.fromTo(
      potPic, 
      {"y": -1000},
      {"y": 0, duration: 1.9*fallingRate, delay: .3}
    )

    gsap.fromTo(
      cupPic, 
      {"y": -1000},
      {"y": 0, duration: 1.8*fallingRate, delay: .6}
    )

    gsap.fromTo(
      rabbitPic, 
      {"y": -1000},
      {"y": 0, duration: 1.65*fallingRate, delay: .4}
    )

    gsap.fromTo(
      aodPic, 
      {"y": -1000},
      {"y": 0, duration: 1.65*fallingRate, delay: .4}
    )

    gsap.fromTo(
      qohPic, 
      {"y": -1000},
      {"y": 0, duration: 1.85*fallingRate, delay: .5}
    )


  }, [])

  return (
    <HeaderWrapper>
      <SvgWrapper>
        {/* <img src={hero} ref={el => {pic=el}}/> */}
        <div className="hero-grid-container">
          <img src={aod} id="aod-id" className="grid-element ios-stretch-fix" ref={el => {aodPic=el}}/>
          <img src={alice} id="alice-id" className="grid-element ios-stretch-fix" ref={el => {alicePic=el}}/>
          <img src={bookshelf} id="bookshelf-id" className="grid-element ios-stretch-fix" ref={el => {bookshelfPic=el}}/>
          <img src={clock} id="clock-id" className="grid-element ios-stretch-fix" ref={el => {clockPic=el}}/>
          <img src={cup} id="cup-id" className="grid-element ios-stretch-fix" ref={el => {cupPic=el}}/>
          <img src={pot} id="pot-id" className="grid-element ios-stretch-fix" ref={el => {potPic=el}}/>
          <img src={qoh} id="qoh-id" className="grid-element ios-stretch-fix" ref={el => {qohPic=el}}/>
          <img src={rabbit} id="rabbit-id" className="grid-element ios-stretch-fix" ref={el => {rabbitPic=el}}/>
        </div>
      </SvgWrapper>
      <Links />
    </HeaderWrapper>
  )
}
