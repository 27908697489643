import React, { useEffect } from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

// components
import Layout from "../components/layout"
import SEO from "../components/seo"
import Navigation from "../components/Nav"
import Hero from "../components/Hero"
import Footer from "../components/Footer"

// assets
import map from "../assets/map2022.svg"
import mapbg from "../assets/map-bg.svg"

import "../styles/variables.css"
import ScrollAnimation from "react-animate-on-scroll"

// function WeAreLions({ big }) {
//   const backgroundFluidImageStack = [
//     big.childImageSharp.fluid,
//     `linear-gradient(rgba(255, 128, 31, 0.7), rgba(255, 128, 31, 0.7))`,
//   ].reverse()
//   return (
//     <StyledBackgroundImage
//       Tag="section"
//       fluid={backgroundFluidImageStack}
//       id="about-us"
//       // style={{ zIndex: -1 }}
//     >
//       <span className="headline">
//         We believe every organization deserves the opportunity to realize itself
//         to the fullest.
//       </span>
//       <p className="message">
//         The League of Independent Organizations (LIONS) is the official
//         autonomous government of unaccredited student organizations in Ateneo de
//         Manila University.
//       </p>
//       <Link to="/about-us">Read more about LIONS</Link>
//     </StyledBackgroundImage>
//   )
// }
// const ClosingImageWrapper = styled.div`
//   height: auto;
//   img {
//     display: block;
//     position: absolute;
//     left: 0;
//     height: auto;
//     width: 100%;
//     z-index: -1;
//   }
// `
// class ClosingImg extends React.Component {
//   constructor(props) {
//     super(props)
//     this.closingImg = React.createRef()
//   }
//   componentDidMount() {
//     this.imgHeight = this.closingImg.current.clientHeight
//   }
//   getImgHeight() {
//     console.log("img height: " + this.imgHeight)
//     return this.imgHeight
//   }
//   render() {
//     return (
//       <ClosingImageWrapper>
//         <img ref={this.closingImg} alt="closing bg" src={bottomUrl} />
//       </ClosingImageWrapper>
//     )
//   }
// }
// console.log(Window.getImgHeight())

//export
const Wrapper = styled.div`
  background: linear-gradient(180deg, #7D2047 0%, #090836 40%, var(--indigo-100) 70%, #090836 85%);

  #cover {
    display: block;
    position: fixed;
    background: linear-gradient(180deg,#711d45 0%,#090836 100%);
    z-index: 3000;
    width: 100vw;
    height: 90vh;
    top: 10vh;
    transition: background 500ms ease-in-out;
    
    @media screen and (max-width: 600px){
      height: 94vh;
      top: 6vh;
    }
  }

`

const SchedMapWrapper = styled.div`
  background: url(${mapbg});
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom;
  // margin-top: 4em;
`

const Map = styled.section`
  display: flex;
  justify-content: center;
  height: 60vw;

  .onsite-info {
    height: 45%;
    width: 40%;
    display: flex;
    flex-direction: column;

    h3 {
      font-size: var(--header-3);
      font-family: "Magnificent", sans-serif;
      color: var(--yellow);
      margin-bottom: 0.2em;
    }

    p {
      font-size: clamp(0.8em, 3vw, 1.4em);
      font-family: "Biko", sans-serif;
      color: var(--white);
      margin-bottom: 0.8em;
    }
  }

  .map {
    height: 100%;
    width: 40%;

    img {
      width: 100%;
    }
  }

  @media screen and (max-width: 900px){
    .onsite-info, .map {
      padding: 0 24px;
    }
  }

  @media screen and (max-width: 600px){
    flex-direction: column;
    height: 140vw;
    margin: 0 0 auto;

    .onsite-info {
      width: 100%;
      height: auto;
      margin-top: -88px;
    }

    .map {
      width: 100%;
      height: auto;
    }
  }
`

export default ({ data }) => {
  useEffect(() => {
    (function() {
      document.getElementById("cover").style.display = "none"
    })();
  });
  return (
    <Layout>
      <SEO title="Join LIONS RecWeek this September 5 - 7 at the Colayco Pavillion"></SEO>
      <Wrapper>
        <div id="cover"></div>
        <Navigation logo={data.logo} smallLogo={data.smallLogo} />
        <Hero />
        <SchedMapWrapper>
          {/* <Schedule>
            <h1>SCHEDULE OF ACTIVITIES</h1>
            <img src="https://www.unfe.org/wp-content/uploads/2019/04/SM-placeholder.png" />
          </Schedule> */}
          <ScrollAnimation animateIn="fadeFromRight">
          <Map>
            <div className="onsite-info">
              <div className="info">
                  <p>ONSITE LIONS' RECRUITMENT WEEK</p>
                  <h3>SEPT 5 - 7</h3>
                  <p>Colayco Pavilion</p>
              </div>
            </div>
            <div className="map">
              <img src={map}/> 
            </div>
          </Map>
          </ScrollAnimation>
        </SchedMapWrapper>
        {/* eslint-disable-next-line */}
        {/* <UpcomingEvents group={data.group}></UpcomingEvents> */}
        {/* <Organizations cards={data.cards} pages={data.pages}></Organizations> */}
        {/* <WeAreLions big={data.big} /> */}
        <Footer data={data} margin="-30%" style={{ zIndex: 999 }} />
      </Wrapper>
    </Layout>
  )
}

export const query = graphql`
  query Images {
    logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fixed(width: 112) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    smallLogo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fixed(width: 96) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    group: file(relativePath: { eq: "group_pic.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 450, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    big: file(relativePath: { eq: "bigpic.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4160, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    cards: allPrismicOrgCard {
      edges {
        node {
          data {
            org__name {
              text
            }
            org_description {
              text
            }
            org_logo {
              localFile {
                absolutePath
                childImageSharp {
                  fixed(quality: 100, width: 40) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
              alt
            }
            org_tags {
              text
            }
            org_category_list {
              org_category
            }
          }
        }
      }
    }
  }
`
