import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Img from "gatsby-image"
import logoPic from "../assets/logo.png"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import RGA from "react-ga"


const Nav = styled.nav`

  .nav-scrolled {
    background-color: var(--magenta-300);
  }

  #lionsNavBar.open {
    @media screen and (max-width: 1130px) {
      background-color: var(--magenta-300);
      transition: 0s;
    }
  }

  #lionsNavBar {
    z-index: 2000;
    position: relative;
    width: 100%;
    font-family: "Biko", sans-serif;
    position: fixed;
    transition: .6s ease-in-out;

    .outerUL {
      display: flex;
      justify-content: space-between;
      align-items: center;
      list-style: none;
      padding: 10px 40px 7px 40px;
      user-select: none;

      @media screen and (max-width: 1024px) {
        padding-left: 40px;
        padding-right: 40px;
      }

      @media screen and (max-width: 900px) {
        padding-left: 24px;
        padding-right: 24px;
      }

      @media screen and (max-width: 425px) {
        padding-left: 24px;
        padding-right: 24px;
      }
      

    a {
      color: #fafafa;
      text-decoration: none;
      font-weight: 500;
      letter-spacing: 0.05em;
      position: relative;
      padding-bottom: 10px;

      #nav-logo {
        height: 48px;
        z-index: 999999;

        @media screen and (max-width: 600px) {
          height: 40px;
        }

        @media screen and (max-width: 425px) {
          height: 30px;
        }
      }

      @media screen and (max-width: 500px) {
        font-size: 12px;
      }
      @media screen and (max-width: 390px) {
        font-size: 10px;
      }

      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 3px;
        bottom: 0;
        left: 0;
        background-color: #fafafa;
        transform: scaleX(0);
        transform-origin: bottom right;
        transition: transform 0.3s;
      }

      &:hover {
        &:after {
          transform-origin: bottom left;
          transform: scaleX(1);
        }
      }
    }

    .innerUL {
      list-style: none;
      display: flex;
      flex-direction: row;
      animation: anim;
      height: 100%;
      transition: .3s ease-in-out;
      // z-index: -1;

      li {
        padding: 0 0 0 2rem;
        font-size: 14px;
        text-align: center; 
        transition: .5s ease-in-out;
      }

      li:active {
        a {
          color: #DFA839;

          &:after{
            background-color: #DFA839 !important;
          }
        }
      }

      @media screen and (max-width: 1130px) {
        position: fixed;
        flex-direction: column;
        height: 100vh;
        width: 100vw;
        left: 0;
        top: 60px;
        user-select: none;
        background: linear-gradient(180deg, #7D2047 0%, #090836 100%);
        gap: 3rem;
        padding-left: 40px;
        transition: .3s ease-in-out;
        
        li {
          text-align: left;
          position: relative;
          top: -100vh;
        }
      }

      @media screen and (max-width: 425px) {
        top: 50px;
      }
    }

    .innerUL.closed {
      height: 0%;
    }

    .innerUL.open {
      li {
        top: 144px;
      }

      @media screen and (max-width: 1024px) {
        padding-left: 100px;
        padding-right: 100px;
      }
  
      @media screen and (max-width: 768px) {
        padding-left: 40px;
        padding-right: 40px;
      }
  
      @media screen and (max-width: 425px) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    .nav-toggle {
      display: none;
      cursor: pointer;
      padding: 8px 0;
      transition: .3s ease-in-out;

      .nav-toggle-bar {
        background-color: #fafafa;
        height: 3px;
        width: 21px;
        transition: .3s ease-in-out;
      }

      .nav-toggle-bar::before, .nav-toggle-bar::after {
        content: '';
        background-color: #fafafa;
        height: 3px;
        width: 21px;
        position: absolute;
        transition: .3s ease-in-out;
      }

      .nav-toggle-bar::before {
        transform: translateY(-6px);
      }

      .nav-toggle-bar::after {
        transform: translateY(6px);
      }

      @media screen and (max-width: 1130px) {
        display: inline;
      }
    }

    .nav-toggle.open {

      .nav-toggle-bar {
        background: transparent;
        transform: translateX(-50px);
      }

      .nav-toggle-bar::before {
        width: 25px;
        transform: rotate(45deg) translate(35px, -35px);
      }

      .nav-toggle-bar::after {
        width: 25px;
        transform: rotate(-45deg) translate(35px, 35px);
      }
    }



    .cta {
      @media screen and (max-width: 720px) {
        margin-left: auto;
        a {
          color: #ffffff;
          padding: 12px 24px;
          font-weight: bold;
          background-color: none;
          border: 1px solid #ffffff;
          box-sizing: border-box;
          border-radius: 6px;
          &:hover {
            color: #ff801d;
            background-color: #fafafa;
            border: 1px solid #ff801d;
            &:after {
              transform-origin: bottom left;
              transform: scaleX(0);
            }
          }
        }
      }
      @media screen and (min-width: 950px) {
        margin-left: auto;

        a {
          color: #ffffff;
          padding: 12px 24px;
          font-weight: bold;
          background-color: none;
          border: 1px solid #ffffff;
          box-sizing: border-box;
          border-radius: 6px;
          &:hover {
            color: #ff801d;
            background-color: #fafafa;
            border: 1px solid #ff801d;
            &:after {
              transform-origin: bottom left;
              transform: scaleX(0);
            }
          }
        }
      }
    }
  }
`

const LogoWrapper = styled(Link)`
  @media screen and (max-width: 500px) {
    div {
      img {
        width: 100px !important;
        height: auto !important;
      }
    }
  }
`

export default function Navigation({ logo }) {   

  
  const[state, setState] = useState(false);
  const changeState=()=> {
    setState(!state);
  }

  const [color, setColor] = useState(false)
    
  const changeColor = () => {
    if (window.scrollY > 40) {
      setColor(true)
    }
    else {
      setColor(false)
    }
  }
  
  useEffect(() => {    
    window.addEventListener("scroll", changeColor)
  });

  return (
    <Nav>
        <div id="lionsNavBar"  className={(color ? 'nav-scrolled' : '') + " " + (state ? "open" : "closed")}>
          <ul className="outerUL">
            <li>
              <LogoWrapper
                to="/"
                onClick={() => {
                  RGA.event({
                    category: "Logo",
                    action: "nav-click",
                  })
                }}
              >
                {/* <Img fixed={logo.childImageSharp.fixed} id="nav-logo"/> */}
                <img src={logoPic} id="nav-logo"/>
              </LogoWrapper>
            </li>
            <ul className={"innerUL " + (state ? "open" : "closed")}>
              <li>
                <Link 
                  activeClassName = "active"
                  activeStyle = {{
                    color: "#DFA839"
                  }}
                  to="/"
                  onClick={() => {
                    RGA.event({
                      category: "home",
                      action: "nav-click",
                    })
                  }}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link 
                  activeClassName = "active"
                  activeStyle = {{
                    color: "#DFA839"
                  }}
                  to="/organizations"
                  onClick={() => {
                    RGA.event({
                      category: "orgs",
                      action: "nav-click",
                    })
                  }}
                >
                  Our Organizations
                </Link>
              </li>
              <li>
                <Link
                  activeClassName = "active"
                  activeStyle = {{
                    color: "#DFA839"
                  }}
                  to="/about-recweek"
                  onClick={() => {
                    RGA.event({
                      category: "about-recweek",
                      action: "nav-click",
                    })
                  }}
                >
                  About RecWeek
                </Link>
              </li>
              <li>
                <Link
                  activeClassName = "active"
                  activeStyle = {{
                    color: "#DFA839"
                  }}
                  to="/about-us"
                  onClick={() => {
                    RGA.event({
                      category: "about-us",
                      action: "nav-click",
                    })
                  }}
                >
                  About LIONS
                </Link>
              </li>
              <li>
                <OutboundLink
                  href="http://bit.ly/LIONSOrgRegistration"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Register Your Org
                </OutboundLink>
              </li>
              <li>
                <Link
                  activeClassName = "active"
                  activeStyle = {{
                    color: "#DFA839"
                  }}
                  to="/faqs"
                  onClick={() => {
                    RGA.event({
                      category: "faqs",
                      action: "nav-click",
                    })
                  }}
                >
                  FAQs
                </Link>
              </li>
              </ul>
              <div className={"nav-toggle " + (state ? "open" : "")} onClick={() => { 
                    changeState();
                    console.log(state)
                  }} >
                <div className = "nav-toggle-bar"></div>
              </div>
              {/* <li className="cta">
                <OutboundLink
                  href="http://bit.ly/LIONSRecWeekEvent"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  RSVP for RecWeek
                </OutboundLink>
              </li> */}
          </ul>
        </div>
    </Nav>
  )
}
