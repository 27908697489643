import React from "react"
import PropTypes from "prop-types"
import { createGlobalStyle } from "styled-components"

const GlobalStyles = createGlobalStyle`
html {
  font-size: 100%;
  font-family: 'Roboto', sans-serif;
  box-sizing:border-box; 
  /* background: #ffffff; */
  background: none;
}
body{
  background: none;
}
  *, *::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing:inherit;
  }

  .fadeUp {
    animation: customeFadeUp;
    animation-duration: .7s !important;
    width: 100%;
  }

  .fadeDown {
    animation: customFadeDown;
    animation-duration: .7s !important;
  }

  .fadeFromLeft {
    animation: customFadeFromLeft;
  }

  .fadeFromRight {
    animation: customFadeFromRight;
  }
  
  @keyframes customeFadeUp {
    0% {
      transform: translateY(100px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  
  @keyframes customFadeDown {
    0% {
      transform: translateY(-100px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  
  @keyframes customFadeFromLeft {
    0% {
      transform: translateX(-100px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  
  @keyframes customFadeFromRight {
    0% {
      transform: translateX(100px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }

  

`

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyles></GlobalStyles>
      {/* <Nav /> */}
      <main style={{ position: "relative", overflow: "hidden" }}>
        {children}
      </main>
      {/* <Footer /> */}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
