import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import poster from "../assets/poster.jpg"
import MagnificentUrl from "../fonts/Magnificent Serif.ttf"
import NightmareUrl from "../fonts/The Nightmare.ttf"
import BikoRegUrl from "../fonts/Biko Regular.otf"

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`${site.siteMetadata.title} | %s`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: "LIONS RecWeek 2022: LIONS Wonderland",
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: "og:image",
          content: poster,
        },
        {
          property: "og:url",
          content: site.siteMetadata.url,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: "LIONS RecWeek 2022: LIONS Wonderland",
        },
        {
          name: "twitter:url",
          content: site.siteMetadata.url,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: "theme-color",
          content: "#51577C",
        },
      ].concat(meta)}
    >
    <link rel="preload" 
          as="font"
          href={MagnificentUrl}
          type="font/ttf"
          crossOrigin="anonymous"/>
    <link rel="preload" 
          as="font"
          href={BikoRegUrl}
          type="font/otf"
          crossOrigin="anonymous"/>
    <link rel="preload" 
          as="font"
          href={NightmareUrl}
          type="font/ttf"
          crossOrigin="anonymous"/>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
